<template>
  <div class="wrapper">
    <br><br><br><br>
    <div class="section">
      <div class="container">
        <div class="text-center">
          <h1 class="title">
            <img :src="image_head_title"
                 alt="project">
          </h1>
          <h4 class="description">
            <br>
            {{language.project_introduction_01}}<br>
            {{language.project_introduction_02}}<br>
            {{language.project_introduction_03}}<br><br>

            {{language.project_introduction_04}}<br>
            {{language.project_introduction_05}}<br><br>

            {{language.project_introduction_06}}<br>
            {{language.project_introduction_07}}<br><br>

            {{language.project_introduction_08}}<br>
            {{language.project_introduction_09}}<br>
            {{language.project_introduction_10}}<br><br>

            {{language.project_introduction_11}}<br>
            {{language.project_introduction_12}}<br>
          </h4>

          <br><br><br>

        </div>
        <div class="md-layout">
          <div class="md-layout-item md-layout-item md-size-33 md-small-size-100">
            <router-link to="/nft/story">
              <img :src="btn.IDOL_CLUB.img" alt="IDOL CLUB"/>
            </router-link>
            <br><br>
          </div>
          <div class="md-layout-item md-layout-item md-size-33 md-small-size-100">
            <a href="https://anipangsupporter.club/" target="_blank">
              <img :src="btn.SUPPORTER_CLUB.img" alt="SUPPORTER CLUB"/>
            </a>
            <br><br>
          </div>
          <div class="md-layout-item md-layout-item md-size-33 md-small-size-100">
            <router-link to="/games/pre-register">
              <img :src="btn.GAME_LINEUP.img" alt="GAME LINE-UP"/>
            </router-link>
            <br><br>
          </div>
        </div>
      </div>
      <br><br>
    </div>
    <video class="mainVideo desktop" autoplay loop="" muted="" poster="" playsinline>
      <source :src="back" type="video/mp4">
    </video>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Mixins from "@/plugins/basicMixins";

export default {
  name: "project",
  mixins: [Mixins.HeaderImage],
  bodyClass: "ac-project",
  computed: {
    ...mapGetters([
      'language',
    ]),
  },
  data() {
    return {
      image_head_bg: require("@/assets/img/pages/project/02_project_bg.png"),
      image_head_title: require("@/assets/img/pages/project/project.png"),
      back: require("@/assets/img/pages/index/03_schedule_BG.mp4"),
      btn: {
        IDOL_CLUB: {
          img: require("@/assets/img/pages/project/project_btn_01.png"),
        },
        SUPPORTER_CLUB: {
          img: require("@/assets/img/pages/project/project_btn_02.png"),
        },
        GAME_LINEUP: {
          img: require("@/assets/img/pages/project/project_btn_03.png"),
        }
      }
    };
  },
}
</script>

<style lang="scss">

.ac-project h1.title{
    margin-bottom: -10px;
}

.ac-project {
  color: white;

  .wrapper {
    text-align: center;

    .mainVideo {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      z-index: 0;
    }

    .section {
      position: relative;
      z-index: 1;
    }

    .description {
      margin: 0 auto;
      max-width: 500px;
      font-weight: 600;
      font-size: 1rem;
      color: white;
      word-break: keep-all;
    }
  }
}

.ac-project .wrapper .description {
    margin: 0 auto;
    max-width: 500px;
    font-weight: 600; font-size: 1rem; color: #fff;
    word-break: keep-all;
}

.ac-default .md-toolbar .md-list-item:hover p,
.ac-default .md-toolbar .md-list-item:hover .material-icons,
.ac-default .md-toolbar .md-list-item:hover .md-ripple {
    color: #D1B5FB !important;
    transition: .2s;
}

.description.text-mod {
    max-width:800px !important; 
    color: #CFCEF4 !important; 
    font-size: 1rem !important;
}

.description.text-mod strong {
    font-weight: 800; color: #ffffff !important;
}

.container .md-layout>.md-layout-item>a {
    color: #9c27b0;
    color: var(--md-theme-default-primary-on-background, #9c27b0);
    width: 230px; height: auto;
    display: inline-block;
    box-shadow: 6px 6px 12px 4px rgba(0, 0, 0, .5);
    border-radius: 18px;
}

.container .md-layout>.md-layout-item>a:hover {
    box-shadow: none;
    transform: translate(0, 1px);
    transition: .15s;
}

.container .md-layout a:hover img {
    filter: brightness(140%);
}
</style>